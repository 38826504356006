<template>
  <div>
    <!-- <div
      class="d-flex justify-content-center align-items-center flex-nowrap mb-1"
    >
      <div
        class="d-flex justify-content-center align-items-center flex-nowrap mr-5"
      >
        <b-form-input
          id="nameFilter"
          v-model="nameFilter"
          placeholder="Search by Voucher no"
        />
      </div> 
      <div
        class="d-flex justify-content-center align-items-center flex-nowrap mr-5"
      >
        <b-form-input
          id="codeFilter"
          v-model="codeFilter"
          placeholder="Search by mess name"
        />
      </div>
      <div
        class="d-flex justify-content-center align-items-center flex-nowrap mr-5"
      >
        <b-form-input
          id="valueFilter"
          v-model="valueFilter"
          placeholder="Search by cnic"
        />
      </div>
    </div> -->

    <div
      class="d-flex justify-content-center align-items-center flex-nowrap mb-1"
    >
      <div
        class="d-flex justify-content-center align-items-center flex-nowrap mr-5"
      >
        <b-button variant="primary" pill @click="filter">
          <feather-icon icon="SearchIcon" class="mr-50" />
          <span class="align-middle">Search</span>
        </b-button>
      </div>

      <div
        class="d-flex justify-content-center align-items-center flex-nowrap mr-5"
      >
        <b-button variant="primary" pill @click="search">
          <feather-icon icon="RefreshCwIcon" class="mr-50" />
          <span class="align-middle">Refresh</span>
        </b-button>
      </div>
    </div>

    <b-card>
      <b-table
        responsive
        :fields="fields"
        :items="roomFeedback"
        details-td-class="m-0 p-0"
        small
        v-if="hasPermission('room_feedback_show')"
        :per-page="0"
        :busy="isBusy"
      >
      <template #cell(other_observation_suggestion)="data">
          <span
            @click="showFullText(data.item.other_observation_suggestion)"
          >
            {{ data.value }}
          </span>
        </template>
        <template #table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner :variant="'primary'" class="align-middle"></b-spinner>
          </div>
        </template>
      </b-table>
      <b-row>
                <b-col md="2">
                    <div style="float:left">
                        <h5 style="margin: 0; display: inline;" class="text-primary">Count: </h5>
                        <h5 style="margin: 0; display: inline;" align="right">
                            <strong>
                                {{totalDataLength}}
                            </strong>
                        </h5>
                    </div>
                </b-col>
            </b-row>
      <div
        class="d-flex justify-content-center align-items-center flex-nowrap m-2"
      >
        <b-pagination
          size="md"
          :total-rows="totalItems"
          v-model="currentPage"
          :per-page="perPage"
        ></b-pagination>
      </div>

       <b-modal
        :id="modalId"
        :title="modalTitle"
        size="lg"
        centered
        hide-footer
        @hidden="resetModal"
        :no-close-on-esc="true"
        :no-close-on-backdrop="true"
        >
        <div class="modal-content">
            <p>{{ modalText }}</p>
        </div>
    </b-modal>

    </b-card>   
  </div>
</template>
  
  <script>
import { mapActions, mapGetters } from "vuex";
import util from "@/util.js";

export default {
  components: {
  },
  data() {
    return {
      fields: [
        // { key: "manage", label: "Manage" },
        { key: "cnic", label: "Cnic" },
        { key: "name", label: "Name" },
        { key: "mobile", label: "Mobile" },
        { key: "mess_name", label: "Mess Name" },
        { key: "rooms_booked", label: "Rooms Booked" },
        { key: "staff_behaviour", label: "Staff Behaviour" },
        { key: "mess_cleanliness", label: "Mess Cleanliness" },
        { key: "room_cleanliness", label: "Room Cleanliness" },
        { key: "food_quality", label: "Food Quality" },
        { key: "application_rating", label: "Application Rating" },
        { key: "overall_mess_rating", label: "Overall Mess Rating" },
        {
            key: "other_observation_suggestion",
            label: "Other Observation",
            formatter: (value) => {
            const maxLength = 30; 
            if (value && value.length > maxLength) {
                return value.slice(0, maxLength) + "...";
            }
            return value;
            },
        },
      ],
      currentPage: 1,
      perPage: 0,
      totalItems: 0,
      roomFeedback: [],
      visible: false,
      totalDataLength: 0,
      isBusy: false,
      allowEdit: true,
      modalId: "full-text-modal",
      modalTitle: "",
      modalText: "",
    };
  },
  mixins: [util],
  async mounted() {
    // await this.search();
  },
  methods: {
    ...mapActions({
      getRoomFeedback: "appData/getRoomFeedback",
    }),
    async filter() {
      this.currentPage = 1;
      await this.search();
    },
    async search() {
      this.isBusy = true;
      await this.fetchPaginatedData();
      this.isBusy = false;
    },
     showFullText(text) {
      this.modalTitle = "Other Observations / Suggestions";
      this.modalText = text;
      this.$bvModal.show(this.modalId);
    },
    resetModal() {
      this.modalTitle = "";
      this.modalText = "";
    },
    viewGlobalPolicy(globalPolicy) {
      this.globalPolicy = globalPolicy;
      this.globalPolicyEditModalCount += 1;
      this.visible = true;
      this.allowEdit = false;
      this.$nextTick(() => {
        this.$bvModal.show("global-policy-edit-modal");
      });
    },

    async fetchPaginatedData() {
      try {
        let tempParams = {
          pageNumber: this.currentPage,
        };
        // if (this.nameFilter) {
        //   tempParams["name"] = this.nameFilter;
        // }
        // if (this.codeFilter) {
        //   tempParams["code"] = this.codeFilter;
        // }
        // if (this.valueFilter) {
        //   tempParams["value"] = this.valueFilter;
        // }
        const res = await this.getRoomFeedback(tempParams);
        console.log(res.data.results);
        this.roomFeedback = res.data;
        console.log('hi', this.roomFeedback);
        this.totalDataLength = res.data.count;
        this.totalItems = res.data.count;
        this.perPage = res.data.per_page;
      } catch (error) {
        this.displayError(error);
      }
    },
  },
  computed: {
    ...mapGetters({
      hasPermission: "appData/hasPermission",
      getLoggedInUser: "appData/getUser",
    }),
  },
  watch: {
    currentPage: async function (val) {
      await this.fetchPaginatedData();
    },
  },
};
</script>
  
<style scoped>
  .modal-content {
  max-height: calc(100vh - 200px);
  overflow-y: auto;
}
</style>
  